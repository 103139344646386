import React from "react";
import { useState, useEffect, FC } from "react";
import { QrReader } from "@cmdnio/react-qr-reader";

const QRScanner: FC<{ onScan: (result: object) => void }> = ({ onScan }) => {
  const [result, setResult] = useState<object | null>(null);
  useEffect(() => {
    if (onScan && result) onScan(result);
  }, [result]);

  return (
    <div>
      <QrReader
        constraints={{ facingMode: "environment" }}
        onResult={(qrresult: any, error) => {
          if (!!qrresult && qrresult.text !== result)
            setResult({ url: qrresult.text });
          else if (!!error && error.name && error.message)
            setResult({
              errorName: error.name,
              errorMessage: error.message,
              url: "",
            });
        }}
        videoContainerStyle={{ position: "static", padding: "0px" }}
        videoStyle={{ background: "black" }}
      />
    </div>
  );
};

export default QRScanner;
